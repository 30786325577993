import React,{Component} from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {signin} from './actions/authActions';
import {clearErrors} from './actions/errorActions';

const useStyles  = theme => ({
    paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  snackbar:{
    position: 'relative',
    width:'100%',
    transform:'none',
    left:0,
    top:0
  },
  placeholder:{
    minHeight:'5rem',
  }
});


class BVSignIn extends Component{
  state = {
    email: '',
    password: '',
    msg: null,
    open: false,
    severity:"success",
  };
  static propTypes = {
    isAuthenticated:PropTypes.bool,
    error: PropTypes.object.isRequired,
    signin: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  };
  componentDidUpdate(prevProps){
    const {error, isAuthenticated} = this.props;

    if (error !== prevProps.error){
      if(error.id ==='LOGIN_FAIL'){
        this.setState({msg: error.msg.msg, open:true, severity:"error"});
      } else {
        this.setState({open: false});
      }
    }
    if (isAuthenticated && isAuthenticated !== prevProps.isAuthenticated){
      this.setState({open:true, msg:"You have been successfully signed in", severity:"success"});
      //window.localStorage.setItem("isAuthenticated", isAuthenticated);
      //window.localStorage.setItem("user", this.props.user);
      window.location.href="/";  
    }
  }
  onChange = e =>{
    this.setState({[e.target.name]:e.target.value, open:false});
    //console.log('onChange: '+JSON.stringify(this.state))
  }

  onSubmit = e =>{
    e.preventDefault();
    const {email, password} = this.state;
    const user = {email, password};
    this.props.signin(user);
    
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({open:false});
  };
  render() {
    
    const { classes } = this.props;
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit = {this.onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={this.onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={this.onChange}
          />
          {/*<FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />*/}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              {/*<Link href="/reset-password" variant="body2">
                Forgot password?
  </Link>*/}
            </Grid>
            <Grid item>
              <Link href="/sign-up" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Grid container justify="flex-end" className={classes.placeholder}>
            
            <Snackbar className={classes.snackbar} open={this.state.open}  anchorOrigin={ {vertical: 'top', horizontal: 'center'} }>

                  <Alert onClose={this.handleClose} severity={this.state.severity}>
                    {this.state.msg}
</Alert>
            </Snackbar>
      </Grid>
      </form>
      </div>
    </Container>
  );
}
};

const mapStateToProp = state =>({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error
})

export default connect(mapStateToProp, {signin, clearErrors})(withStyles(useStyles)(BVSignIn));
