import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Slide } from '@material-ui/core';
import BVHeadSign from './bv-scope/BVHeadSign/BVHeadSign'
const drawerWidth = 100;

function AcademyDrawer(props) {
  const { mobileToggle } = props;
  console.log("AcademyDrawer:"+ JSON.stringify(props));
  //console.log("AcademyDrawer:"+ JSON.stringify(BVHeadSign.mobileOpen));
  //const mobileOpen = BVHeadSign.mobileOpen;

  /*const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };*/

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.getElementById('drawer-container')/*window().document.body */: undefined;
  const containerRef = React.useRef(null);
  const xsDisplay = mobileToggle ? "block" :"none"
  return (

            <Slide direction="right" in={true} mountOnEnter unmountOnExit backgroundColor= {'primary.dark'}>
            
          {drawer}
          </Slide>

  );
}

AcademyDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  //window: PropTypes.func,
  mobileToggle: PropTypes.bool,
};

export default AcademyDrawer;