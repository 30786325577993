export default [
  {
      path: "/cpp",
      title: "C++",
      children: [
        { path:"/syntax",
          title: "Syntax",
          description:[
            {type:'h1', value:"Basic Syntax in C++"},
            {type:'h2', value:"Program Structure"},
            {type:"p", value:"The basic c++ program includes: "},
            {type:"p", value:"  1. Header definitions"},
            {type:"p", value:"  2. Function main() - the entry point of the program."},
            {type:"code", lang:'cpp', value:`#include <iostream> //header definition

int main (){  //entry point
  using namespace std;
  
  cout << "Hello world!" << endl;
  return 0;
}`},
            {type:"ps", value:"C++ program also contains "},
            {type:'ps', value: "global declarations"},
            {type:'ps',value:", user defined "},
            {type:'a', value: "functions", href: "/cpp/functions"},
            {type: 'ps', value: " and "},
            {type:'a', value:"classes", href: "/cpp/classes"},
            {type:"pc", value:"."},
            {type:'h2', value:"Comments"},
            {type:'br'},
            {type:"p", value:"There are two types of comments in C++. "},
            {type:"ps", value:""},
            {type:"bold", value:'  "/* <comment> */" '},
            {type:"pc", value:"- multiline comment bounded by forward slash and asterisk symbol mirrored at both ends ; "},
            {type:"ps", value:""},
            {type:"bold", value:'  "// <comment>"'},
            {type:"pc", value:"- one line comment."},
            {type:"code", lang:'cpp', value:`/*1. Multiline comment (line 1) 
                      (line 2) */
//2. One line comment`},
            {type:'h2',value:'Preprocessor directives'},
            {type:'br'},
            {type:"p", value:"Preprocessor directives follows after a hash sign (#) and occupy only one line. Preceding the newline character at the end of the line by a backslash (\) allows to extend preprocessor derective to more than one line ."},
            {type:'p',value:'"#include" tells the preprocessor to include the contents of a specified file. This directive should be placed before the content usage.'},
            {type:'ps',value:'There is two forms of this directive: '},
            {type:'bold',value:'quoted '},
            {type:'ps', value:'form and '},
            {type:'bold',value:'angle-bracket '},
            {type:'pc', value:'form.'},
            {type:'code', lang:'cpp',value:`#include "path-to-project-header-file" // This method is normally used to include programmer-defined header files.
#include <path-to-library-header-file> // This method is usually used to include system header files.`},
            {type:"p", value:'For the quoted form (#include "path-to-project-header-file") the search of included file is following:'},
            {type:"p", value:"    1.  The current file directory"},
            {type:"p", value:"    2.  Follows the search path used for the angle-bracket form."},
            {type:"br"},
            {type:"p", value:'For the angle-bracket (#include <path-to-library-header-file>) form the search of included file is following:'},
            {type:"p", value:"    1. Along the path that's specified by each -I (/I) compiler option."},
            {type:"p", value:"    2. Along the paths that are specified by the INCLUDE environment variable."},
            {type:'h2',value:'Statements'},
            {type:'br'},
            {type:'p', value:'Each individual statement must be ended with a semicolon. Statements can be written in one line.'},
            {type:"code", lang: 'cpp', value:`  a = b; b = b*a;
  Academy academy;
  academy->RegisterStudent(name, grade);`
            },

            {type:'h2',value:'Blocks'},
            {type:'br'},
            {type:'p', value:'A block is a set of logically connected statements that are surrounded by enclosed in curly brackets. The indentation from the beginning of the line is not important and used for better readability of the program.'},
            {type: 'code', land: 'cpp', value:`{
  Academy myAcademy;
  unsigned int id = myAcademy.RegisterStudent("Jhon", 1);
  if (myAcademy.Subscribe("math", id)) {
      cout << "Jhon subscribed to math.";
  }
}`}
         ]
        },
        {
          path:"/preprocessor-directives",
          title:"Preprocessor directives",
          description:[
            {type:"p", value:""},
          ],
        },
        {
          path:"/built-in-types",
          title:"Built-in Types",
          description:[
            {type:"p", value:""},
          ],
        },
        {
          path:"/operators",
          title:"Operators",
          description:[
            {type:"p", value:""},
          ],
        },
        {
          path:"/conditions",
          title:"Conditions",
          description:[
            {type:"p", value:""},
          ],
        },
        {
          path:"/loops",
          title:"Loops",
          description:[
            {type:'h1', value:"Functions in C++"},
            {type:"p", value:""},
          ],
        },
        {
          path:"/arrays",
          title:"Arrays",
          description:[
            {type:'h1', value:"Arrays in C++"},
            {type:"p", value:"C++ array is a data structure, which stores a fixed-size sequential collection of elements of the same type."},
            {type:"p", value:"All arrays consist of contiguous memory locations. The lowest address corresponds to the first element and the highest address to the last element."},
            {type:"p", value:"Array indexing starts from zero."},
            {type:"p", value:"Declaring an array, instead of the bunch of the separate values, is very useful when it's necessary to perform the same operations with them."},
            {type:"br"},
            {type:"p", value:"Example:"},
            {type:"code", lang: 'cpp', value:`int a[5] = {3, 2, 6, 8, 9};
for (int i = 0; i < 5; i++)
{
    std::cout << "Number " << a[i] << " is " << ((a[i] % 2 != 0) ? "odd." : "even.") << std::endl ;
}`},
{type:"p", value:"Output:"},
{type:"code", lang: 'bash', value:`Number 3 is odd.
Number 2 is even.
Number 6 is even.
Number 8 is even.
Number 9 is odd.`},
{type:"p", value:"The following syntax gives the same result as above."},
{type:"code", lang: 'c++', value:`int a[5] = {3, 2, 6, 8, 9};
for (int i = 0; i < 5; i++)
{
    std::cout << "Number " << *(a+i) << " is " << ((*(a+i) % 2 != 0) ? "odd." : "even.") << std::endl ;
}`}            
          ]
        },
        {
          path:"/functions",
          title:"Functions",
          description:[
            {type:'h1', value:"Functions in C++"},
            {type:"p", value:""},
          ],
        },
        {
          path:"/classes",
          title:"Classes",
          description:[
            {type:'h1', value:"Classes in C++"},
            {type:"p", value:"Example:"},
            {type:"code", lang: 'c++', value:`#include <iostream>
#include <map>
#include <set>

using namespace std;
         
const int CAPACITY = 100;
            
class Academy { // keyword "class" identifies the class declaration. The class name becomes the name of that user-defined type. Class members can be data types or functions.
public:
  typedef struct tSubject {
    string name;
    int capacity;
    int minGrade;
  } TSubject;
  typedef  struct tStudent {
      unsigned int id;
      string name;
      int grade;
      set<string> sbjs;
  } TStudent;
  Academy(); //constructor
  ~Academy(); //destructor
  unsigned  RegisterStudent(const char* name, int grade);
  bool Subscribe(const char* sbj, unsigned int id);
  bool UnSubscribe(const char* sbj, unsigned int id);
protected: // the protected keyword indentifies the members that can be accessed from derived classes - the classes that inherit from this class
  void createSubjects();
  void deleteSubjects();
  int getFreePlaces(const char* sbj) const;
private: //The private keyword identifies the members of the class, which can only be accessed through public (data hiding) member functions.
  typedef map<unsigned int, TStudent*> TStudentMap;
  typedef map<string, TSubject*> TSubjectMap;
  TStudentMap mStudents;
  TSubjectMap mSbjs;
  unsigned int mLastStudentId;
};
Academy::Academy():mLastStudentId(0) {
  createSubjects();
}
Academy::~Academy() {
  deleteSubjects();
}
void Academy::createSubjects(){
  mSbjs.insert(TSubjectMap::value_type("math", new tSubject({"math", CAPACITY, 1})));
  mSbjs.insert(TSubjectMap::value_type("en", new tSubject({"en", CAPACITY, 1 })));
  mSbjs.insert(TSubjectMap::value_type("history", new tSubject({"history", CAPACITY, 2 })));
  mSbjs.insert(TSubjectMap::value_type("chemestry", new tSubject({"chemistry", CAPACITY, 3 })));
  mSbjs.insert(TSubjectMap::value_type("physics", new tSubject({"physics", CAPACITY, 3 })));
}
void Academy::deleteSubjects() {
  for (auto sbj = mSbjs.begin(); sbj != mSbjs.end(); sbj++) {
    delete sbj->second;
  }
  for (auto st = mStudents.begin(); st != mStudents.end(); st++) {
        delete st->second;
  }
}
unsigned int Academy::RegisterStudent(const char* name, int grade)
{
  mStudents.insert(TStudentMap::value_type(mLastStudentId, new tStudent({ mLastStudentId, name, grade })));
  mLastStudentId++;
  return mLastStudentId - 1;
}
bool Academy::Subscribe(const char* sbj, unsigned int id) {
  TSubjectMap::const_iterator sbjIt = (!sbj) ? mSbjs.end() : mSbjs.find(sbj);
  if (sbjIt == mSbjs.end()) {
    cout << "Unknown subject!" << endl;
    return false;
  }
  if (sbjIt->second->capacity <= 0) {
    cout << "No places left" << endl;
    return false;
  }
  TStudentMap::iterator stIt = mStudents.find(id);
  if (stIt == mStudents.end()) {
    cout << "Invalid Student Id!" << endl;
    return false;
  }
  if (sbjIt->second->minGrade > stIt->second->grade) {
    cout << "Student grade does not meet course requirement" << endl;
    return false;
  }
  //check if student already enroled
  if (stIt->second->sbjs.find(sbj) != stIt->second->sbjs.end()) {
    cout << "Student #"<< id <<" already enrolled" << endl;
    return false;
  }
  stIt->second->sbjs.insert(sbj);
  sbjIt->second->capacity--;
  return true;
}
bool Academy::UnSubscribe(const char* sbj, unsigned int id) {
  TSubjectMap::const_iterator sbjIt = (!sbj) ? mSbjs.end() : mSbjs.find(sbj);
  if (sbjIt == mSbjs.end()) {
    cout << "Unknown subject!" << endl;
    return false;
  }
  TStudentMap::iterator stIt = mStudents.find(id);
  if (stIt != mStudents.end()) {
    cout << "Invalid Student Id!" << endl;
    return false;
  }
  if (stIt->second->sbjs.find(sbj) == stIt->second->sbjs.end()) {
    cout << "Student not enrolled" << endl;
    return false;
  }
  stIt->second->sbjs.erase(sbj);
  return true;
}
int Academy::getFreePlaces(const char* sbj) const{
  TSubjectMap::const_iterator sbjIt = (!sbj) ? mSbjs.end() : mSbjs.find(sbj);
  if (sbjIt == mSbjs.end()) {
    cout << "Unknown subject!" << endl;
    return 0;
  }
  return sbjIt->second->capacity;
}

int main(){
  Academy myAcademy;
  unsigned int id = myAcademy.RegisterStudent("Jhon", 1);
  if (myAcademy.Subscribe("math", id)) {
    cout << "Jhon subscribed to math.";
  }
}`},
          ],
        },
      ],
      //url: '/cpp',
  },
];