import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
//import PropTypes from 'prop-types';

interface ToggleProps {
    ClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export function BVToggleButton(Props: ToggleProps) {
    return   <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="start"
    onClick={Props.ClickHandler}
    sx={{ mr: 2, display: { sm: 'none' } }}
  ><MenuIcon /></IconButton>
}
