import axios from 'axios';
import {returnErrors} from './errorActions'
import {
  USER_LOADING, USER_LOADED, AUTH_ERROR,
  LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS,
  REGISTER_SUCCESS, REGISTER_FAIL
} from './types';


export const signin = ({email, password}) => dispatch =>{
  const config = {
    headers:{
      'Context-Type': 'application/json'
    }
  }
  //console.log('signin: '+JSON.stringify({email, password}));
  axios.post('/api/auth', {email, password}, config)
    .then(res => dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    }))
    .catch(err => {
      //console.log(JSON.stringify(err));
      dispatch(returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL'));
      dispatch({
        type: LOGIN_FAIL
      })
    })
}
export const logout = () => {
  return {type: LOGOUT_SUCCESS}
}
export const loadUser = () =>(dispatch, getState) => {
  //console.log("loading user...");
    dispatch({type:USER_LOADING});
  axios.get('/api/auth/user', tokenConfig(getState))
    .then(res => dispatch({
      type: USER_LOADED,
      payload: res.data
    }))
    .catch(err => {

      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({type: AUTH_ERROR});
      //console.log('user cannot be loaded'+JSON.stringify(err));
    });
}

export const signup = ({firstName, lastName, email, password}) => dispatch =>{
  const config = {
    headers:{
      'Context-Type': 'application/json'
    }
  }
  //console.log('signup: '+JSON.stringify({firstName, lastName, email, password}));
  //const body = JSON.stringify({firstName, lastName, email, password});
  //console.log('signup: body = '+ body);
  axios.post('/api/users', {firstName, lastName, email, password}, config)
    .then(res => dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    }))
    .catch(err => {
      //console.log(JSON.stringify(err));
      dispatch(returnErrors(err.response.data, err.response.status, 'REGISTER_FAIL'));
      dispatch({
        type: REGISTER_FAIL
      })
    })
}
export const password_reset = (email) => {
  const config = {
    headers:{
      'Context-Type': 'application/json'
    }
  }
  axios.post('/api/reset-password', {email}, config)
  return {type: LOGOUT_SUCCESS}
}

export const tokenConfig = getState =>{
  const token = getState().auth.token;
  const config = {
    headers:{
      "Content-type":"application/json"
    }
  }
    if (token){
      config.headers['x-auth-token'] = token;
    }
  return config;
}