import React, { useState, useCallback } from 'react';
import { Fragment } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';

import { bvUseStyles } from '../BetaVersionStyles';
import Logout from './Logout';

import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { BVToggleButton } from '../BVToogleButton';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}));

function BVHeadSign(props) {
  console.log("BVHeadSign:");
 const classes =useStyles();
  const { sections, title, submenuIcon, auth} = props;
  //console.log("BVHeadSign:"+ JSON.stringify({props}));
  var a = auth;
  if (!a){
   a = {isAuthenticated: false, user:null};
   console.log("auth not exist!");
  } 
  var si = submenuIcon;
  if (!si){
    si = (
      <div></div>
    )
  }
  const { isAuthenticated, user } = a;
  //console.log("Header:"+ JSON.stringify(auth)+"sections:" + JSON.stringify(sections)+" : " + title);
  const authLinks = (
    <Fragment>
      <Box mx={3} px={3} sx={{display: { xs: 'none', sm: 'block' } }}><span><strong>{user ?`Wellcome ${user.firstName} ${user.lastName}` :'' }</strong></span></Box>
      <Logout/>{/*}
      <Button variant="contained" size="small" align = "end" color="primary" className='mx-3' onClick={logout}>
  Logout
  </Button>*/}
</Fragment>
  )
  const guestLinks = (
    <Fragment>
  <Button variant="contained"  size="small" color="primary" href="/sign-in" >
    {'Sign in'}
  </Button>
  </Fragment>
  )
  
  return (
    <React.Fragment>
      <CssBaseline />
    <Toolbar className={classes.toolbar}>
      {/*<Button size="small">Subscribe</Button> */}
      <Box className={`${classes.toolbarTitle}`}>

      <Box display="flex" alignItems="center">
      <Link href = "https://beta-version.org"
      underline="none"
      align="left"
      noWrap
      className={`no-visit`}
      color="secondary"
      //style={{ textDecoration: 'none' }}
      
      >
        <Avatar alt="Beta Version Logo" src="bv-logo-rocket-round.png" variant="rounded" className={classes.rounded}/>
      </Link>
      <Link href = "/"
      underline="none"
      align="left"
      noWrap
      className={`${classes.toolbarTitle} no-visit`}
      color="secondary"
      //style={{ textDecoration: 'none' }}
      
      >
      <Typography
        component="h2"
        variant="h5"
        //color="inherit"
        align="left"
        noWrap
        className={classes.toolbarTitle}
        my="auto"
      >
        <Box ml="3rem" fontStyle="italic" fontWeight = {500}>{title}</Box>
        </Typography>
        </Link>
        </Box>
      </Box>

      {/*<IconButton>
        <SearchIcon />
      </IconButton>
      */}
      {isAuthenticated ? authLinks : guestLinks}


    </Toolbar>
    {
    <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
      {si}
      { 
        sections.map((section) => (
        <Link
          color="inherit"
          noWrap
          key={section.title}
          variant="body2"
          href={section.path}
          className={classes.toolbarLink}
          //datParam={auth.token}
          //to={{pathname: section.url}}
          params={{ testvalue: "hello" }}
        >
          {section.title}
        </Link>
      ))}
      </Toolbar>}
  </React.Fragment>
  );
}

BVHeadSign.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
  submenuIcon: PropTypes.object,
};


const mapStateToProp = state =>{

  console.log("mapStateToProp" +JSON.stringify(state));

  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProp, {})(BVHeadSign);

