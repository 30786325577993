
import React, { Component, Fragment, useState } from 'react';
import { Container, CssBaseline} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';


import { bvUseStyles } from './bv-scope/BetaVersionStyles';
import Box from '@material-ui/core/Box'
import AcademyContent from './AcademyContent';
import AcademyDrawer from './AcademyDrawer'
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import BVHeadSign from './bv-scope/BVHeadSign/BVHeadSign'
import Hidden from '@material-ui/core/Hidden';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@mui/material/Paper';
import Highlight from 'react-highlight';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { ExitToAppSharp } from '@material-ui/icons';

const sliderWidth = 240;

function AcademyContainer(props, routeProps) {
  const {mobileToggle, section, handleToggle, history} = props;
  const subTitleIdx = (props.subTitleIdx !== null && props.subTitleIdx >=0 && props.subTitleIdx<AcademyContent[section].children.length) ?  props.subTitleIdx : 0;
  const [subTitleCnt, setSubTitle]=useState(subTitleIdx);
  /*const drawSubTitleText = (value) =>{

  };*/
  //setSubTitle
  const drawSubTitleContent = () => {
    console.log('drawSubTitleContent'+JSON.stringify(AcademyContent[section].children[subTitleCnt].title));
    var keepSpans=[];
    return(
    AcademyContent[section].children[subTitleCnt].description.map((item) => {

      switch(item.type){
      case "h1": return <Typography variant='h1'>{item.value}</Typography>;
      case "h2": return <Typography variant='h2'>{item.value}</Typography>;
      case "p": return <Typography>{item.value}</Typography>;
      case "br": return <br />;
      case "code": return <SyntaxHighlighter language={item.lang} style={docco} overflow = {'auto'}>
      {item.value}
    </SyntaxHighlighter>;
      case "ps": {keepSpans.push(<span>{item.value}</span>);break;}
      case "bold": {keepSpans.push(<span style={{"font-weight":"bold"}}>{item.value}</span>);break;}
      case "a": {keepSpans.push(<Link href = {item.href}>{item.value}</Link>);break;}
      case "pc": {
         keepSpans.push(<span>{item.value}</span>);
         var ret = <Typography>{keepSpans.map(function(sp){return sp;})}</Typography>;
         keepSpans=[];
         return ret;
      }     
      default: return <Typography>TBD</Typography>;
    }
  }
    )
  )

  };
  const drawerContent = (
    <Paper sx={{ m: 1}} /*elevation={4}*/>
    <Box backgroundColor={'primary.dark'} >
      <Typography variant="h4">{AcademyContent[section].title}</Typography>
      <Divider />
      <List backgroundColor={'primary.dark'} >
        {AcademyContent[section].children.map((s, index) => (
          <ListItem button key={s.title} backgroundColor={'primary.dark'} onClick={()=>{history.push(AcademyContent[section].path + AcademyContent[section].children[index].path);setSubTitle(index); handleToggle.call()}}>
            <ListItemText primary={s.title} />
          </ListItem>
        ))}
      </List>
      {/*<Box
        component="polygon"
        sx={{
          fill: (theme) => theme.palette.common.white,
          stroke: (theme) => theme.palette.divider,
          strokeWidth: 1,
        }}
        points="0,100 50,00, 100,100"
      />*/}
    </Box>
  </Paper>
  );

  return (
    <Box sx={{ display: 'flex', p: 1 }}>
    <Hidden smUp implementation="css">
    <Box minWidth = {`${sliderWidth}px`} zIndex = {10} position={'absolute'} >
           <Slide direction="right" in={mobileToggle} mountOnEnter unmountOnExit >
            {drawerContent}
           </Slide>
    </Box>
    </Hidden>

    <Hidden xsDown implementation="css">
    <Box width = {`${sliderWidth}px`} /*minHeight={"70vh"}*/>
           <Slide direction="right" in={true} mountOnEnter unmountOnExit>
            {drawerContent}
           </Slide>
     </Box>
    </Hidden>

        <Box sx={{ m: 1}} minWidth={'100px'}>
          {drawSubTitleContent()}
        </Box>

      </Box>
     );
}
AcademyContainer.propTypes = {
  section: PropTypes.number,
  mobileToggle: PropTypes.bool,
  handleToggle:PropTypes.func.isRequired,
}; 
export default AcademyContainer;
//export default connect(mapStateToProp, {find})(withStyles(bvUseStyles)(AcademyContainer));
