import React, {Component} from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme, withStyles} from '@material-ui/core/styles';
import {Provider} from 'react-redux';

import { BVToggleButton } from './components/bv-scope/BVToogleButton';
import store from './store';
import BVHeadSign from './components/bv-scope/BVHeadSign/BVHeadSign'
import { loadUser } from './components/bv-scope/BVHeadSign/actions/authActions';
import BVSignIn from './components/bv-scope/BVHeadSign/SignInModule'
import BVSignUp from './components/bv-scope/BVHeadSign/SignUpModule'
import AcademyContent from './components/AcademyContent';
import AcademyContainer from './components/AcademyContainer'
import Footer from './components/bv-scope/Footer';
import { AssistantDirection } from '@mui/icons-material';

class Academy extends Component{
  constructor(props) {
    super(props);
    this.handleToggle = this.handleMobileMenuOpen.bind(this);
    this.state = {mobileToggle:false};
  }
  componentDidMount(){
    console.log('App::componentDidMount going to load user.');
    store.dispatch(loadUser());
  }
  handleMobileMenuOpen(mo){
    console.log("Academy:handleMobileMenuOpen");
    console.log(this.state.mobileToggle);
    Promise.resolve()
      .then( res => {this.setState({mobileToggle: !this.state.mobileToggle})});
    //this.setState({mobileOpen: mo});
  }
  getSectionChildrenRoutes(section, sectionIdx){
    return section.children.map((child, childIdx)=>{
      console.log("Academy:getSectionChildrenRoutes "+child.title);
      return(<Route exact path={section.path+child.path} render={routeProps =>
          <AcademyContainer section={sectionIdx} mobileToggle={this.state.mobileToggle} handleToggle = {this.handleToggle} subTitleIdx = {childIdx} {...routeProps}/>}
        />)
      })    
  }

  render() {
  return (
    <Provider store={store}>
     <MuiThemeProvider theme={createMuiTheme({palette: {secondary: {main: '#F44336'}}})}>
     <BVHeadSign  title='Academy' sections={AcademyContent} submenuIcon = {<BVToggleButton  ClickHandler={this.handleToggle}/>}/>
     <BrowserRouter>
      <Switch>
      <Route exact path="/" render={routeProps => 
        <AcademyContainer section={0} mobileToggle={this.state.mobileToggle} handleToggle = {this.handleToggle} {...routeProps}/>}
      />
      {/*to do: create routes according to content*/}
      {
         AcademyContent.map((section, sectionIdx)=>{
          this.getSectionChildrenRoutes(section, sectionIdx);
          return(<Route exact path={section.path} render={routeProps =>
            <AcademyContainer section={sectionIdx} mobileToggle={this.state.mobileToggle} handleToggle = {this.handleToggle} {...routeProps}/>}
          />)
         
        })
      }
      {
        AcademyContent.map((section, sectionIdx)=>{
          return this.getSectionChildrenRoutes(section, sectionIdx);
        })
       }

      <Route exact path="/sign-in">
            <BVSignIn/>
      </Route>
      <Route exact path="/sign-up">
            <BVSignUp/>
      </Route>
      </Switch>
      </BrowserRouter>
      <Footer  />
    </MuiThemeProvider>
    </Provider>
   );
}
}
//export default App;
export default Academy;
